import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "../../../../axios/index";
import MetricUsage from "../../Pages/Sessions/MetricUsage";
import AuthContext from "../../../../hooks/useAuth";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AndroidIcon from "@mui/icons-material/Android";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/DeleteForever";
import Edit from "@mui/icons-material/Edit";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography } from "@mui/material";
import "./AllSession.css";
import { ThemePreferenceContext } from "../../../../index";
import MetricCpuMain from "../Sessions/MetricCpuMain";
import MetricGpuMain from "../Sessions/MetricGpuMain";
import MetricFpsMain from "../Sessions/MetricFpsMain";
import MetricMemory from "../Sessions/MetricMemory";
import MetricUploadData from "../Sessions/MetricUploadData";
import MetricDownloadData from "../Sessions/MetricDownloadData";
import MetricFpsStability from "../Sessions/MetricFpsStability";
import MetricAppPower from "../Sessions/MetricAppPower";
import MetricPeakMemory from "../Sessions/MetricPeakMemory";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/AddCircle";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Divider } from "@mui/material";
import { FormControl, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import BookIcon from "@mui/icons-material/Book";
import { debounce } from "lodash";
import AppleIcon from "@mui/icons-material/Apple";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CloseIcon from "@mui/icons-material/Close";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import VideoSection from "../Sessions/VideoGraphs/VideoSection";
import GraphSection from "../Sessions/VideoGraphs/GraphSection";
export default function Home(props) {
  const auth = useContext(AuthContext);
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );
  const uid = auth.id || currentUserId;
  const role = auth.role;
  const [User, setUser] = useState("");
  const [FirstUserdata, SetFirstUserdata] = React.useState([]);
  const [selectedUseritem, setSelectedUseritem] = React.useState("");
  const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
  const [selecteditem, setSelecteditem] = React.useState("");
  const [UserActive, setUserActive] = useState(false);
  const [AllSessionsActive, setAllSessionsActive] = useState(false);
  const [Allsessions, setAllsessions] = useState("");
  const [epoch_time, setEpoch_time] = useState("")
  const [selectedDevice, setSelectedDevice] = React.useState("");
  const [allSession, setAllSession] = useState([]);
  const UsermenuRef = useRef("");
  const [calls, setcall] = useState(uid);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [selectedVideoGraph, setSelectedVideoGraph] = useState(null);
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickDelOpen = () => {
    setOpenDel(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShareLinkOpen(false);
    handleDialogClose();
    handleNotesDialogClose();
    setOpenDel(false);
  };
  const [sessionNoteTitle, setsessionNoteTitle] = useState(null);
  const [sessionNoteDes, setsessionNoteDes] = useState(null);
  const [sessionNotekey, setsessionNotekey] = useState(null);
  const [sessionNote, setSessionNote] = useState(false);
  const [editSessionNote, setEditSessionNote] = useState(false);
  const [sessionTitle, setSessionTitle] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editNotesDialogOpen, setEditNotesDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [editTitle, setEditTitle] = useState(null);
  const [editSession, setEditSession] = useState(null);
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [Firstsessiondata, SetFirstsessiondata] = React.useState([]);
  const [Firstdevicedata, SetFirstdevicedata] = React.useState([]);
  const [Seconddevicedata, SetSeconddevicedata] = React.useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [device, setDevices] = useState("");
  const [date, setDate] = useState("Date");
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [filterTab, setFilterTab] = useState(false);
  const [pageScroll, setPageScroll] = useState(1);
  const [appNameList, setAppNameList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [appName, setAppName] = useState([]);
  const [sessionName, setSessionName] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [deleteIcon, setDeleteIcon] = useState(true);
  const [sessionNoteDrop, setSessionNoteDrop] = useState(false);
  const [selectedSessionTitle, setSelectedSessionTitle] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [bookmarkedSessions, setBookmarkedSessions] = useState({});

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
    setRotationAngle(0);
  };

  const handleZoomIconClick = () => {
    if (imageLoaded) {
      setIsModalOpen(true);
      openModal(timeStamp);
    } else {
      setTimenull("a");
      toast.error("Kindly,Pick a Image to Explore!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  let isPortrait = false;

  const rotateImage = () => {
    setRotationAngle(rotationAngle - 90);
  };

  const handleShareClick = () => {
    setShareLinkOpen(true);
  };

  const handleSessionDialogClose = () => {
    setShareLinkOpen(false);
  };

  const handleSessionNotes = () => {
    setSessionNote(true);
  };

  const handleSessionNotesClose = () => {
    setSessionNote(false);
  };

  useEffect(() => {
    ringko();
  }, [sessionNotekey]);

  const handleEditSessionNotes = (id) => {
    console.log("key", id);
    setsessionNotekey(id);
    setEditSessionNote(true);
  };

  function ringko() {
    addNote();
  }

  const handleEditSessionNotesClose = () => {
    setEditSessionNote(false);
  };

  const handleInputSessionTitle = (event) => {
    setsessionNoteTitle(event.target.value);
  };

  const handleInputSessionDescription = (event) => {
    setsessionNoteDes(event.target.value);
  };
  const handleInputEditSessionTitle = (event) => {
    setEditTitle(event.target.value);
    handleInputSessionTitle(editTitle);
  };

  const handleInputEditSessionDescription = (event) => {
    setEditSession(event.target.value);
    handleInputSessionDescription(editSession);
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };
  const handleNotesEditClick = () => {
    setEditNotesDialogOpen(true);
  };
  const handleInputChange = (event) => {
    setNewTitle(event.target.value);
    setSelectedDevice(event.target.value);
    const value = event.target.value;
    setSearchText(value);
    setShowClearIcon(value ? "flex" : "none");
    handleTagSearch(value);
  };

  const handleSaveTitle = () => {
    const name = newTitle;
    setSelectedDevice(() => name);
    setSessionTitle(newTitle);
    onChange(newTitle);
    updateSession();
    handleDialogClose();
    setNewTitle("");
  };

  const initialValue = {
    sessionname: "",
  };
  const [formData, setFormData] = useState(initialValue);

  const onChange = (e) => {
    const sessionname = e;
    const sessionId = currentUserId.session_id;
    setFormData({ sessionname, sessionId });
  };

  const handleChangeApp = (event) => {
    const {
      target: { value },
    } = event;

    setAppName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeSession = (event) => {
    const {
      target: { value },
    } = event;

    setSessionName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeDevice = (event) => {
    const {
      target: { value },
    } = event;

    setDeviceName(typeof value === "string" ? value.split(",") : value);
  };

  const handleCloseSelect = () => {
    setIsOpen(false);
  };

  const handleOkButtonClick = () => {
    setIsOpen(false);
  };

  const handleFilterTab = () => {
    setFilterTab(!filterTab);
  };

  const handleCloseFilter = () => {
    setFilterTab(false);
  };

  const handleApplyFilter = () => {
    const uniqueSessionNames = new Set();
    const filteredItems = [];
    if (
      appName.length === 0 &&
      sessionName.length === 0 &&
      deviceName.length === 0
    ) {
      toast.error("Choose at least 1 to Apply Filter!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    allSession.forEach((item) => {
      const matchesAppname =
        appName.length === 0 || appName.includes(item.app_name);
      const matchesSessionName =
        sessionName.length === 0 || sessionName.includes(item.sessionname);
      const matchesDeviceName =
        deviceName.length === 0 || deviceName.includes(item.device_name);

      if (matchesAppname && matchesSessionName && matchesDeviceName) {
        if (!uniqueSessionNames.has(item.sessionname)) {
          uniqueSessionNames.add(item.sessionname);
          filteredItems.push(item);
        }
      }
    });

    setFilteredList(filteredItems);
    setFilterTab(false);
  };

  const handleClearFilters = () => {
    setDeviceName([]);
    setAppName([]);
    setSessionName([]);
    setFilteredList(allSession);
    setFilterTab(false);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };
  const handleNotesDialogClose = () => {
    setEditNotesDialogOpen(false);
  };

  const [notes, setNotes] = useState("");

  useEffect(() => {
    let isMount = true;
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (isMount) {
          SetFirstUserdata(res.data.data);
        }
      })
      .catch((err) => { });
    return () => {
      isMount = false;
    };
  }, []);

  const dropdownRef = useRef(null);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSessionNoteDrop(false);
      setUserActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  function Users(e, data, i) {
    const day = data.id;
    setcall(data.id);
    setAllSession([]);
    setPageScroll(1);
  }

  useEffect(() => {
    Sessions(pageScroll);
  }, [calls]);

  function singleUserItem(e, data, id) {
    setSelectedUseritem(id);
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        singleSessionItem(e, data, id);
      });
  }

  function Sessions(pageNum) {
    axios
      .get("/mySessions", {
        params: {
          userId: calls,
          page: pageNum,
          size: 50,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.data?.length) {
          setAllSession((prevRes) => [...prevRes, ...res.data.data]);

          // Initialize or update bookmarkedSessions based on the new data
          setBookmarkedSessions((prevBookmarks) => {
            const newBookmarks = { ...prevBookmarks };
            res.data.data.forEach((session) => {
              newBookmarks[session.session_id] = session.is_bookmarked === "true";
            });
            return newBookmarks;
          });
        }
        singleSessionItem();
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error);
        }
      });
  }

  function getAllFilterList() {
    const distinctValuesApp = new Set();
    const distinctValuesSession = new Set();
    const distinctValuesDevice = new Set();
    let appList = [];
    let sessionList = [];
    let deviceList = [];

    for (const obj of allSession) {
      const valueApp = obj["app_name"];
      const valueSession = obj["sessionname"];
      const valueDevice = obj["device_name"];

      if (!distinctValuesApp.has(valueApp)) {
        distinctValuesApp.add(valueApp);
        appList.push(valueApp);
      }
      if (!distinctValuesSession.has(valueSession)) {
        distinctValuesSession.add(valueSession);
        sessionList.push(valueSession);
      }
      if (!distinctValuesDevice.has(valueDevice)) {
        distinctValuesDevice.add(valueDevice);
        deviceList.push(valueDevice);
      }
    }

    setAppNameList(appList);
    setSessionList(sessionList);
    setDeviceList(deviceList);
  }

  useEffect(() => {
    if (allSession?.length) {
      getAllFilterList();
    }
  }, [allSession]);

  //Log file Download function
  async function opener() {
    const response = await fetch(
      `https://gamemondev-service.indiumsoft.com/downloadlogs?fileName=${Secondsessiondata.session_id}`
    );
    if (response.status === 200) {
      window.open(
        `https://gamemondev-service.indiumsoft.com/downloadlogs?fileName=${Secondsessiondata.session_id}`
      );
    } else {
      toast.error("No Log File for this Session !", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      var timer = setInterval(function () {
        clearInterval(timer);
      }, 1000);
    }
  }

  //Update session Title
  function updateSession() {
    // console.log("UPDATE", selectedDevice);
    // console.log("UPDATE", Secondsessiondata.session_id);
    const values = JSON.stringify({
      sessionID: Secondsessiondata.session_id,
      newTitle: selectedDevice,
    });
    axios
      .put("/updateSessionTitle", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        singleSessionItem(null, Secondsessiondata, selecteditem);
        sessiondressdetails(Secondsessiondata.session_id);
        toast.success("Modified Successfully!");
      })
      .catch((err) => {
        toast.error("Error Occurred!");
      });
  }

  function addNote() {
    const newEditTitle = {};
    const newEditSession = {};
    notes &&
      notes?.forEach((note) => {
        if (sessionNotekey === note.id) {
          newEditTitle[note.id] = note.session_notes_title;
          newEditSession[note.id] = note.session_notes;
        }
      });

    setEditTitle(newEditTitle[sessionNotekey]);
    setsessionNoteTitle(newEditTitle[sessionNotekey]);
    setEditSession(newEditSession[sessionNotekey]);
    setsessionNoteDes(newEditSession[sessionNotekey]);
  }

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  //Edit session Note
  function editnote() {
    if (isEditing) {
      return;
    }

    setIsEditing(true);
    const values = JSON.stringify({
      id: sessionNotekey,
      sessionNotesTitle: editTitle,
      sessionNotes: editSession,
    });
    axios
      .put("/editSessionNotes", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleEditSessionNotesClose();
        sessiondressdetails(Secondsessiondata.session_id);
        {
          const errorMessage = "Session details edited !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .finally(() => {
        setIsEditing(false);
      });
  }

  //Delete Session Note
  function checksnote(id) {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);

    axios
      .delete("/deleteSessionNotes", {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        sessiondressdetails(Secondsessiondata.session_id);
        {
          const errorMessage = "Session Deleted !";
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  //Display session notes
  function sessiondressdetails(sessionid) {
    axios
      .get("/fetchallSessionNotes", {
        params: {
          sessionID: sessionid,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        setNotes(res.data.data);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data)
        );
        // global.sessionNotesTitle = data.sessionNotesTitle;
      });
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  function singleSessionItem(e, data, id) {
    // console.log("CHECKED DATA : ",data)
    // console.log("CHECKED ID : ",id)
    setSelecteditem(id);
    axios
      .get("/sessionDetails", {
        params: {
          DeviceId: data.device_id,
          appName: data.app_name,
          userId: uid,
          sessionId: data.session_id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSecondsessiondata(res.data.data[0]);
        // console.log("epoch: ",res.data.data[0].epoch_time)
        setEpoch_time()
        sessiondressdetails(data.session_id);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data[0])
        );
        global.screenshot_timestamp = data.screenshot_timestamp;
        global.sessionname = data.sessionname;
        global.deviceid = data.device_id;
        global.userid = data.user_id;
        global.appname = data.app_name;
        global.sessionid = data.session_id;
        global.gpu_usage_time = data.gpu_usage_time;
        global.epoch_time = data.epoch_time;
        setTimeout(() => {
          setSelectedDevice(data.sessionname);
        }, 200);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          SetSecondsessiondata([]);
        }
      });
  }

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getdataid = (xaxis, yaxis) => {
    screenshot(xaxis);
  };
  // /?filename=9ee8ae91-6232-4b34-a339-5637bd6ea8d1-1701148923247.png

  const [timeStamp, setTimeStamp] = useState("");
  const [timeNull, setTimenull] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  function screenshot(xaxis) {
    const newArray = [];
    if (Secondsessiondata?.screenshot_timestamp) {
      newArray.push(Secondsessiondata?.screenshot_timestamp);
    }

    if (newArray.length !== 0) {
      const value = Secondsessiondata?.gpu_usage_time.indexOf(xaxis);
      const values = Secondsessiondata?.screenshot_timestamp[value];
      const img = `https://gamemondev-service.indiumsoft.com/getImage/?filename=${Secondsessiondata?.session_id}-${Secondsessiondata?.screenshot_timestamp[value]}.png`;
      console.log("images", img);
      setTimenull(Secondsessiondata?.screenshot_timestamp[value]);
      setTimeStamp(img);
      setIsLoading(true);
    }
  }

  const handleImageLoads = () => {
    setIsLoading(false);
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setImageError(true);
    setImageLoaded(false);
  };

  //Add session note
  function handleFormAddSubmit() {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const values = JSON.stringify({
      sessionID: Secondsessiondata.session_id,
      sessionNotesTitle: sessionNoteTitle,
      sessionNotes: sessionNoteDes,
      user_id: uid,
      user_name: Secondsessiondata.user_name,
    });
    axios
      .post("/addSessionNotes", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        sessiondressdetails(Secondsessiondata.session_id);
        handleSessionNotesClose();
        setsessionNoteTitle("");
        setsessionNoteDes("");

        {
          const errorMessage = "Session Note Added !";
          toast.success(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    SetFirstdevicedata(savedValues?.Firstdevicedata);
    setsessionNoteTitle(savedValues?.sessionNoteTitle);
    SetSeconddevicedata(savedValues?.Seconddevicedata);
    SetFirstsessiondata(savedValues?.Firstsessiondata);
    SetSecondsessiondata(savedValues?.Secondsessiondata);
    setNotes(savedValues?.notes);
    // setStartDate(savedValues?.startDate)
    setEndDate(savedValues?.endDate);
    setDevices(savedValues?.device);
    // setApplication(savedValues?.application)
    // setSessions(savedValues?.sessions)
    // setUserName(savedValues?.user_name)
    setUser(savedValues?.User);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      Firstdevicedata,
      sessionNoteTitle,
      Seconddevicedata,
      Firstsessiondata,
      Secondsessiondata,
      date,
      device,
      notes,
      // application,
      // sessions,
      startDate,
      endDate,
      User,
      // userName
    };
    window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  useEffect(() => {
    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  }, [allSession]);

  const handleClickGraph = (graphId) => {
    setSelectedGraph(graphId);
    setSelectedVideoGraph(graphId);
  };

  const renderGraphComponent = () => {
    switch (selectedGraph) {
      case 1:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricCpuMain try={"small"} data={uid} senddata1={getdataid} />
          </div>
        );
      case 2:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricGpuMain try={"small"} senddata1={getdataid} />
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricMemory try={"small"} senddata1={getdataid} />
          </div>
        );
      case 4:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricFpsMain try={"small"} senddata1={getdataid} />
          </div>
        );
      case 5:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricDownloadData try={"small"} senddata1={getdataid} />
          </div>
        );
      case 6:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricUploadData try={"small"} senddata1={getdataid} />
          </div>
        );
      case 7:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricPeakMemory try={"small"} senddata1={getdataid} />
          </div>
        );
      case 8:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricFpsStability try={"small"} senddata1={getdataid} />
          </div>
        );
      case 9:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricAppPower try={"small"} senddata1={getdataid} />
          </div>
        );
      default:
        return null;
    }
  };
const [video,setVideo] = useState(true);
const [videoUrl, setVideoUrl] = useState('');

const videoFunction = async () => {
     // Call the API when the video is enabled
     try {
      const response = await axios.get(`/getVideoUrl?filename=${Secondsessiondata?.session_id}`, {
        // headers: {
        //   Authorization: `Bearer ${auth.token}`,
        // },
      });
      console.log("URL:::",response.data); 
      setVideoUrl(response.data);
    } catch (error) {
      console.error("Error fetching the video URL:", error);
    }
  if(video === false){
    setVideo(true)
  }
else{
  setVideo(false)
}
};
  const handleBatteryClick = () => {
    const errorMessage = "Oops! No stats available!";
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleTagSearch = (query) => {
    axios.get(`/sessionTags?Tags=['${query}']`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
    .then(response => {
      // Handle the response
      console.log(response.data);
    })
    .catch(error => {
      // Handle the error
      console.error('There was an error fetching the session tags!', error);
    });
  };
  
      
  const handleChangeSearch = (event) => {
    let val = event.target.value;
    setShowClearIcon(val === "" ? "none" : "flex");
    setSearchText(val);

    const filteredItems = allSession.filter(
      (list) =>
        list.app_name.toLowerCase().includes(val.toLowerCase()) ||
        list.sessionname.toLowerCase().includes(val.toLowerCase())
    );

    setFilteredList(filteredItems);
  };

  const handleClickRemoveText = () => {
    setSearchText("");
    setShowClearIcon("none");
    handleTagSearch(""); 
    const sortedSessions = allSession?.sort(
      (a, b) => new Date(b.session_date) - new Date(a.session_date)
    );
    setFilteredList(sortedSessions);
  };

  const handleAddBookmark = (e, sessionId) => {
    e.stopPropagation();
    setBookmarkedSessions((prev) => ({
      ...prev,
      [sessionId]: true,
    }));
    const values = JSON.stringify({
      session_id: sessionId,
      user_id: uid,
    });
    axios
      .post("/bookmark", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status) {
          toast.dismiss();
          toast.success("Bookmark Added!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteBookmark = (e, sessionId) => {
    e.stopPropagation();
    setBookmarkedSessions((prev) => ({
      ...prev,
      [sessionId]: false,
    }));
    axios
      .delete("/removeBookmark", {
        data: {
          session_id: sessionId,
          user_id: uid,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          toast.dismiss();
          toast.error("Bookmark Removed!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteSession = (sessionId) => {
    setAllSession([]);
    axios
      .delete("/deleteSession", {
        params: {
          sessionId: sessionId,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status) {
          const Message = "Session Deleted !";
          toast.error(Message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          Sessions(pageScroll);
          setDeleteIcon(!deleteIcon);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          toast.error("Deletion Failed !!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
      e.target.clientHeight <
      50;
    if (bottom) {
      setPageScroll((prevRes) => prevRes + 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 400);

  const toggleSessionNoteDrop = (title) => {
    if (selectedSessionTitle === title) {
      setSessionNoteDrop(!sessionNoteDrop);
    } else {
      setSelectedSessionTitle(title);
      setSessionNoteDrop(true);
    }
  };

  useEffect(() => {
    if (pageScroll > 1) {
      Sessions(pageScroll);
    }
  }, [pageScroll]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAppNames, setFilteredAppNames] = useState([]);
  const [filteredSessionNames, setFilteredSessionNames] = useState([]);
  // other states mentioned previously: allSession, appNameList, sessionList, appName

  useEffect(() => {
    // Effect to filter and set filtered app names based on selected device
    if (deviceName.length > 0) {
      const filteredApps = allSession
        .filter(item => deviceName.includes(item.device_name))
        .map(item => item.app_name);
      const uniqueApps = [...new Set(filteredApps)];
      setFilteredAppNames(uniqueApps);
    } else {
      setFilteredAppNames([...appNameList]);
    }
  }, [deviceName, allSession, appNameList]);

  useEffect(() => {
    // Effect to filter and set filtered session names based on selected device and app
    if (deviceName.length > 0 && appName.length > 0) {
      const filteredSessions = allSession
        .filter(item => deviceName.includes(item.device_name) && appName.includes(item.app_name))
        .map(item => item.sessionname);
      const uniqueSessions = [...new Set(filteredSessions)];
      setFilteredSessionNames(uniqueSessions);
    } else {
      setFilteredSessionNames([...sessionList]);
    }
  }, [deviceName, appName, allSession, sessionList]);

  const [isOpenDevice, setIsOpenDevice] = useState(null);
  const [isOpenApp, setIsOpenApp] = useState(null);
  const [isOpenSession, setIsOpenSession] = useState(null);

  const toggleDropdown = (dropdownType) => {
    if (dropdownType === "device") {
      setIsOpenDevice(isOpenDevice === null ? dropdownType : null);
      setIsOpenApp(null); // Close other dropdowns
      setIsOpenSession(null);
    } else if (dropdownType === "app") {
      setIsOpenApp(isOpenApp === null ? dropdownType : null);
      setIsOpenDevice(null); // Close other dropdowns
      setIsOpenSession(null);
    } else if (dropdownType === "session") {
      setIsOpenSession(isOpenSession === null ? dropdownType : null);
      setIsOpenDevice(null); // Close other dropdowns
      setIsOpenApp(null);
    }
  };

  const toggleDevice = (device) => {
    // Function to toggle selected devices
    const updatedDevices = deviceName.includes(device)
      ? deviceName.filter((d) => d !== device)
      : [...deviceName, device];
    setDeviceName(updatedDevices);
  };
  const toggleAppName = (appname) => {
    const updatedApps = appName.includes(appname)
      ? appName.filter((item) => item !== appname)
      : [...appName, appname];
    setAppName(updatedApps);
  };
  const toggleSessionName = (sessionname) => {
    const updatedSessions = sessionName.includes(sessionname)
      ? sessionName.filter((item) => item !== sessionname)
      : [...sessionName, sessionname];
    setSessionName(updatedSessions);
  };
  const handleClickOutside = (event) => {
    // Function to handle click outside dropdown to close it
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Effect to add and remove event listener for outside click detection
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [timestamp, setTimestamp] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const handlePointClick = (point) => {
    setTimestamp(point.timestamp);
  };

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
  };
  
  return (
    <div style={{ width: "70%", marginTop: "5%" }}>
      {/* Left */}
      <div
        style={{
          display: "flex",
          float: "left",
          position: "fixed",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl>
            <TextField
              size="small"
              variant="outlined"
              value={searchText}
              placeholder={"Search"}
              onChange={handleChangeSearch}
              InputProps={{
                style: {
                  background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                  border:
                    currentTheme === "dark"
                      ? "1px solid #646667"
                      : "1px solid #DFDFDF",
                  color: currentTheme === "dark" ? "#FFFFFF" : "#292E33",
                  width: "21.5vw",
                  borderRadius: "5px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ display: showClearIcon, cursor: "pointer" }}
                    onClick={handleClickRemoveText}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px",
              background: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
              boxShadow: "0px 2px 6px #29489803",
              borderRadius: "5px",
              marginLeft:"0.5%"
            }}
            onClick={handleFilterTab}
          >
            <TuneIcon
              sx={{
                fontSize: 30,
                color: currentTheme === "dark" ? "#FFFFFF" : "",
                cursor: "pointer",
              }}
              titleAccess="Filters"
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              background: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
              boxShadow: "0px 2px 6px #29489803",
              borderRadius: "5px",
              marginLeft:"0.5%"
            }}
            onClick={handleFilterTab}
          >
            <SellOutlinedIcon
              sx={{
                fontSize: 30,
                color: currentTheme === "dark" ? "#FFFFFF" : "",
                cursor: "pointer",
              }}
              titleAccess="Search by Tag"
            />
          </div> */}
        </div>
        <div
          value={currentTheme}
          onChange={(e) => {
            // setCurrentTheme(e.target.value);
          }}
          style={{
            display: AllSessionsActive != 404 ? "block" : "none",
            marginTop: "1.5%",
            cursor: "pointer",
            width: "25vw",
            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
            borderRadius: "10px",
            height: "78vh",
            overflowY: "auto",
            overflowX: "hidden",
            scrollBehavior: "smooth",
            boxShadow: "0px 2px 6px #0000000A",
          }}
          className="ScrollBars"
          onScroll={(e) => debouncedHandleScroll(e)}
        >
          {!filterTab ? (
            filteredList?.length > 0 ? (
              filteredList.map((data, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    setAllsessions(e.target.textContent);
                    setAllSessionsActive(!AllSessionsActive);
                    singleSessionItem(e, data, i);
                    setSelectedGraph("");
                  }}
                  style={{
                    background:
                      selecteditem === i
                        ? "transparent linear-gradient(180deg, #6398F5 0%, #4475EE 100%) 0% 0% no-repeat padding-box"
                        : currentTheme === "dark"
                          ? "#292E33"
                          : "white",
                    color:
                      selecteditem === i
                        ? "white"
                        : currentTheme === "dark"
                          ? "white"
                          : "black",
                    fontSize: "20px",
                    borderRadius: "8px",
                    marginLeft: "5px",
                    marginBottom: "-4px",
                    width: "97%",
                  }}
                >
                  {/* Session Information */}
                  <ListItem>
                    {data.device_name === "apple" ? (
                      <AppleIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    ) : (
                      <AndroidIcon sx={{ fontSize: 40, marginLeft: "-15px" }} />
                    )}
                    <ListItemText>
                      <div
                        // variant="body"
                        style={{
                          fontWeight: "bold",
                          marginLeft: "15px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          // textTransform:"capitalize",
                          color:
                            selecteditem === i
                              ? "white"
                              : currentTheme === "dark"
                                ? "white"
                                : "#4779EF",
                        }}
                        title={data?.app_name}
                        className="sess-appname"
                      >
                        {/* {data?.app_name} */}
                        {data?.app_name
                          ? data?.app_name.length > 25
                            ? data?.app_name.substring(0, 25) + "..."
                            : data?.app_name
                          : ""}
                        {/* {data?.app_name && (data.app_name.includes('.') ? data.app_name.split('.').pop().slice(-20) : data.app_name)} */}
                      </div>
                      <Typography
                        variant="body1"
                        style={{
                          textTransform: "capitalize",
                          marginLeft: "15px",
                          color:
                            selecteditem === i
                              ? "white"
                              : currentTheme === "dark"
                                ? "#C5CACF"
                                : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        {data.device_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginLeft: "15px",
                          color:
                            selecteditem === i
                              ? "white"
                              : currentTheme === "dark"
                                ? "#C5CACF"
                                : "#494949",
                        }}
                        id="scroll-fonts"
                      >
                        Time Played:{" "}
                        <span
                          style={{
                            color:
                              selecteditem === i
                                ? "white"
                                : currentTheme === "dark"
                                  ? "#C5CACF"
                                  : "#494949",
                            fontWeight: "600",
                          }}
                          id="scroll-fonts"
                        >
                          {data?.session_duration !== undefined && (
                            <span>
                              {typeof data.session_duration === 'number' ? (
                                <>{formatTime(data.session_duration)}</>
                              ) : (
                                <>
                                  {data.session_duration && typeof data.session_duration === 'string' && data.session_duration.includes(':') ? (
                                    data.session_duration
                                  ) : (
                                    formatTime(data.session_duration)
                                  )}
                                </>
                              )}
                            </span>
                          )}
                          {/* {formatTime(data.session_duration)} */}
                        </span>
                      </Typography>
                    </ListItemText>
                    <div
                      style={{
                        marginBottom: "2vh",
                        display: "flex",
                        gap: "10%",
                      }}
                    >
                      {selecteditem === i && (
                        <DeleteIcon
                          className="scroll-icon"
                          titleAccess="Delete"
                          // onClick={handleClickDelOpen}
                          onClick={() => handleDeleteSession(data.session_id)}
                        />
                      )}

                      {bookmarkedSessions[data.session_id] ? (
                        <BookIcon
                          className="scroll-icon"
                          titleAccess="Remove Bookmark"
                          onClick={(e) => handleDeleteBookmark(e, data.session_id)}
                        />
                      ) : (
                        <BookOutlinedIcon
                          className="scroll-icon"
                          titleAccess="Add Bookmark"
                          onClick={(e) => handleAddBookmark(e, data.session_id)}
                        />
                      )}
                    </div>
                    {/* <Dialog
            PaperProps={{
              sx: {
                width: "425px",
                height: "225px",
                backgroundColor:
                  currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
              },
            }}
            open={openDel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <DialogTitle
                style={{
                  fontSize: "20px",
                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
                // id="alert-dialog-title"
              >
                Delete Session
              </DialogTitle>
              <CancelIcon
                onClick={handleClose}
                style={{
                  marginTop: "4.3%",
                  cursor: "pointer",
                  marginRight: "2.5vh",
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
              />
            </div>
            <Divider
              style={{
                margin: "0vw 1.5vw",
                width: "80%",
                backgroundColor: "grey",
              }}
            />
            <DialogContent>
              <h4 style={{ color: "grey" }}>
                Are you sure, you want to delete the session ?
              </h4>
            </DialogContent>
            <DialogActions
              style={{
                marginLeft: "1vw",
                display: "flex",
                justifyContent: "row",
                marginRight: "5vw",
                marginTop: "125px",
                // marginBottom:'1vh',
                position: "fixed",
                gap: "20px",
              }}
            >
              <List>
                <div
                  style={{
                    color: "#FFFFFF",
                    marginTop: "8%",
                    background: "#4779EF",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "3px",
                    fontSize: "15px",
                  }}
                >
                  <ListItem
                    button
                    onClick={() => handleDeleteSession(data.session_id)}
                    style={{
                      paddingLeft: "30%",
                      // marginTop: "4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Proceed
                  </ListItem>
                </div>
              </List>
              <List onClick={handleClose}>
                <div
                  style={{
                    color: "#4779EF",
                    marginTop: "8%",
                    border: "2px solid #4779EF",
                    boxShadow: "white",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "7px",
                    fontSize: "15px",
                  }}
                >
                  <ListItem
                    button
                    onClick={handleClose}
                    style={{
                      paddingLeft: "30%",
                      marginTop: "-4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </ListItem>
                </div>
              </List>
            </DialogActions>
          </Dialog> */}
                  </ListItem>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                      margin: "5px 0",
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: currentTheme === "dark" ? "white" : "black",
                }}
              >
                {AllSessionsActive == 404
                  ? "No Recent sessions"
                  : "No Recent logs!"}
              </div>
            )
          ) : (
            // Filter tab
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "5%",
              }}
            >
              <div
                style={{
                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                            sans-serif`,
                  color: currentTheme === "dark" ? "#A2B1C2" : "#494949",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "text",
                }}
              >
                Filters:
                <span
                  onClick={handleClearFilters}
                  style={{
                    float: "right",
                    cursor: "pointer",
                    color:
                      deviceName.length > 0 ||
                        sessionName.length > 0 ||
                        appName.length > 0
                        ? "#4779EF"
                        : "transparent",
                  }}
                >
                  Clear Filter
                </span>
              </div>  
              <FormControl sx={{ m: 5, width: 600 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "4%",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ paddingTop: "9%" }}>
                    <InputLabel
                      id="device-label"
                      style={{
                        color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                        fontSize: "14px",
                      }}
                    >
                      Device
                    </InputLabel>
                    <div ref={dropdownRef} style={{ position: "relative" }}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown("device");
                        }}
                        className="dropdown-button"
                        style={{
                          backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "#818E94" : "black",
                          textTransform: "capitalize",
                          width: "22vw",
                          height: "6vh",
                          marginTop: "1%",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
                          {deviceName.length > 0 ? deviceName.join(", ") : ""}
                        </div>
                        <span className="down-arrow">&#x2BC6;</span>
                      </div>


                      {isOpenDevice === "device" && (
                        <div
                          className="dropdown-content"
                          style={{
                            display: "block",
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "#818E94" : "black",
                            width: "22vw",
                            maxHeight: "200px",
                            overflowY: "auto",
                            position: "absolute",
                            zIndex: 1,
                            marginTop: "1px",
                            fontSize: "14px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                              width: "19.5vw",
                              margin: "10px",
                              marginBottom: "-5px",
                              padding: "5px",
                              borderRadius: "5px",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              border:
                                currentTheme === "dark"
                                  ? "1px solid #646667"
                                  : "1px solid #DFDFDF",
                              outline: "none",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "white" : "black"
                            }}
                          />
                          <div
                            style={{
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "#818E94" : "black",
                              marginLeft: "1%",
                              padding: "10px",
                            }}
                          >
                            {deviceList
                              .filter((device) => device.toLowerCase().includes(searchTerm.toLowerCase()))
                              .sort((a, b) => a.localeCompare(b))
                              .map((name) => (
                                <div
                                  key={name}
                                  onClick={() => {
                                    toggleDevice(name);
                                    setIsOpenDevice(null);
                                    setSearchTerm("");
                                  }}
                                  style={{
                                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                    color: currentTheme === "dark" ? "#818E94" : "black",
                                    textTransform: "capitalize",
                                    padding: "5px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={deviceName.includes(name)}
                                    onChange={() => toggleDevice(name)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{ marginRight: "8px" }}
                                  />
                                  {name}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ paddingTop: "9%" }}>
                    <InputLabel
                      id="app-name-label"
                      style={{
                        color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                        fontSize: "14px",
                      }}
                    >
                      App Name
                    </InputLabel>
                    <div ref={dropdownRef} style={{ position: "relative" }}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown("app");
                        }}
                        className="dropdown-button"
                        style={{
                          backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "#818E94" : "black",
                          width: "22vw",
                          height: "6vh",
                          marginTop: "1%",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>{appName.length > 0 ? appName.join(", ") : ""}</div>
                        <span className="down-arrow">&#x2BC6;</span>
                      </div>

                      {isOpenApp === "app" && (
                        <div
                          className="dropdown-content"
                          style={{
                            display: "block",
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "#818E94" : "black",
                            width: "22vw",
                            maxHeight: "200px",
                            overflowY: "auto",
                            position: "absolute",
                            zIndex: 1,
                            marginTop: "1px",
                            fontSize: "14px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                              width: "19.5vw",
                              margin: "10px",
                              marginBottom: "-5px",
                              padding: "5px",
                              borderRadius: "5px",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              border:
                                currentTheme === "dark"
                                  ? "1px solid #646667"
                                  : "1px solid #DFDFDF",
                              outline: "none",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "white" : "black"
                            }}
                          />
                          <div
                            style={{
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "#818E94" : "black",
                              marginLeft: "1%",
                              padding: "10px",
                            }}
                          >
                            {filteredAppNames
                              .filter((appname) => appname.toLowerCase().includes(searchTerm.toLowerCase()))
                              .sort((a, b) => a.localeCompare(b))
                              .map((name) => (
                                <div
                                  key={name}
                                  onClick={() => {
                                    toggleAppName(name);
                                    setIsOpenApp(null);
                                    setSearchTerm("");
                                  }}
                                  style={{
                                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                    color: currentTheme === "dark" ? "#818E94" : "black",
                                    padding: "5px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={appName.includes(name)}
                                    onChange={() => toggleAppName(name)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{ marginRight: "8px" }}
                                  />
                                  {name}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div style={{ paddingTop: "9%" }}>
                    <InputLabel
                      id="session-label"
                      style={{
                        color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                        fontSize: "14px",
                      }}
                    >
                      Session
                    </InputLabel>
                    <div ref={dropdownRef} style={{ position: "relative" }}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown("session");
                        }}
                        className="dropdown-button"
                        style={{
                          backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "#818E94" : "black",
                          width: "22vw",
                          height: "6vh",
                          marginTop: "1%",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>{sessionName.length > 0 ? sessionName.join(", ") : ""}</div>
                        <span className="down-arrow">&#x2BC6;</span>
                      </div>

                      {isOpenSession === "session" && (
                        <div
                          className="dropdown-content"
                          style={{
                            display: "block",
                            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "#818E94" : "black",
                            width: "22vw",
                            maxHeight: "200px",
                            overflowY: "auto",
                            position: "absolute",
                            zIndex: 1,
                            marginTop: "1px",
                            fontSize: "14px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                              width: "19.5vw",
                              margin: "10px",
                              marginBottom: "-5px",
                              padding: "5px",
                              borderRadius: "5px",
                              textTransform: "capitalize",
                              fontSize: "14px",
                              border:
                                currentTheme === "dark"
                                  ? "1px solid #646667"
                                  : "1px solid #DFDFDF",
                              outline: "none",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "white" : "black",
                            }}
                          />
                          <div
                            style={{
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "#818E94" : "black",
                              marginLeft: "1%",
                              padding: "10px",
                            }}
                          >
                            {filteredSessionNames
                              .filter((session) => session.toLowerCase().includes(searchTerm.toLowerCase()))
                              .sort((a, b) => a.localeCompare(b))
                              .map((name) => (
                                <div
                                  key={name}
                                  onClick={() => {
                                    toggleSessionName(name);
                                    setIsOpenSession(null);
                                    setSearchTerm("");
                                  }}
                                  style={{
                                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                                    color: currentTheme === "dark" ? "#818E94" : "black",
                                    padding: "5px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={sessionName.includes(name)}
                                    onChange={() => toggleSessionName(name)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{ marginRight: "8px" }}
                                  />
                                  {name}
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    marginTop: "19vh",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: "180px",
                      height: "48px",
                      borderRadius: "40px",
                      marginTop: "-10vh",
                      background:
                        "transparent linear-gradient(180deg, #659AF5 0%, #4374EE 100%) 0% 0% no-repeat padding-box",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif`,
                    }}
                    onClick={handleApplyFilter}
                  >
                    Apply Filters
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "48px",
                      borderRadius: "40px",
                      marginTop: "-10vh",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#494949",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif`,
                    }}
                    onClick={handleCloseFilter}
                  >
                    Cancel
                  </Button>
                </div>
              </FormControl>
            </div>
          )}
        </div>
      </div>
      {/* right */}
      <div className="right">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="sessionContainer"
            >
              <div
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                }}
                className="icon-container"
              >
                <PhoneAndroidIcon className="icon" />
              </div>
              <div style={{ width: "80%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "white" : "#293134",
                        // textTransform:"capitalize",
                      }}
                      title={Secondsessiondata?.app_name}
                      className="container-fonts"
                    >
                      {/* {Secondsessiondata?.app_name} */}
                      {Secondsessiondata?.app_name
                        ? Secondsessiondata?.app_name.length > 25
                          ? Secondsessiondata?.app_name.substring(0, 25) + "..."
                          : Secondsessiondata?.app_name
                        : ""}
                      {/* {Secondsessiondata?.app_name && (Secondsessiondata.app_name.includes('.') ? Secondsessiondata.app_name.split('.').pop().slice(-20) : Secondsessiondata.app_name)} */}
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts1"
                    >
                      <span>Version: {""}</span>
                      <span style={{ fontWeight: "600" }}>
                        {Secondsessiondata?.version_name}
                      </span>
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts2"
                    >
                      <span>Last Updated: {""}</span>
                      <span style={{ fontWeight: "600" }}>
                        {Secondsessiondata?.created_at}
                      </span>
                    </div>
                  </div>
                  <div>
                    {Secondsessiondata === undefined ? (
                      <MoreVertIcon
                        disabled={true}
                        sx={{ fontSize: 20, color: "#4779EF" }}
                      />
                    ) : (
                      <>
                        <MoreVertIcon
                          onClick={handleClick}
                          titleAccess="More"
                          size="small"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          sx={{
                            fontSize: 20,
                            color: "#4779EF",
                            cursor: "pointer",
                          }}
                        />
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              backgroundColor:
                                currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32)",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "& .MuiMenuItem-root": {
                                fontSize: "14px",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                                fontWeight: "400",
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                                backgroundColor:
                                  currentTheme === "dark"
                                    ? "#363C43"
                                    : "#FFFFFF",
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: "yellow",
                                },
                                "& .MuiMenuItem-root:hover": {
                                  backgroundColor: "pink",
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                  backgroundColor: "red",
                                },
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            onClick={handleShareClick}
                            style={{
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            }}
                          >
                            Share Session
                          </MenuItem>
                          <Dialog
                            open={shareLinkOpen}
                            onClose={handleSessionDialogClose}
                            PaperProps={{
                              style: {
                                backgroundColor:
                                  currentTheme === "dark"
                                    ? "#1F2327"
                                    : "#FFFFFF",
                              },
                            }}
                          >
                            <DialogTitle
                              style={{
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                              }}
                            >
                              Share Session
                            </DialogTitle>
                            <CancelIcon
                              onClick={handleClose}
                              style={{
                                cursor: "pointer",
                                marginLeft: "90%",
                                marginTop: "-8vh",
                                marginBottom: "1vh",
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                              }}
                            />
                            <Divider
                              style={{ margin: "1vh", backgroundColor: "grey" }}
                            />
                            <DialogContent>
                              <h5
                                style={{
                                  color: "#818E94",
                                  fontsize: "10px",
                                  fontWeight: "300",
                                  marginBottom: "1%",
                                }}
                              >
                                Copy the link below
                              </h5>
                              <TextField
                                fullWidth
                                InputProps={{
                                  style: { color: "#818E94" },
                                }}
                                // value={`http://gamemon.s3-website-us-west-2.amazonaws.com/Sharing/gamemondev-service.indiumsoft.com/shareSessionDetails?DeviceId=${Secondsessiondata?.device_id}&appName=${Secondsessiondata?.app_name}&sessionId=${Secondsessiondata?.session_id}&userId=${uid}`}
                                value={`https://gamemondev.indiumsoft.com/shareSessionDetails?sessionId=${Secondsessiondata?.session_id}&idn=${Secondsessiondata?.epoch_time}`}
                              />
                              <CopyToClipboard
                                text={`https://gamemondev.indiumsoft.com/shareSessionDetails?sessionId=${Secondsessiondata?.session_id}&idn=${Secondsessiondata?.epoch_time}`}
                                onCopy={() => {
                                  handleClose();
                                  toast.success("Copied Successfully!!!");
                                }}
                              >
                                <button
                                  style={{
                                    marginTop: "25px",
                                    width: "auto",
                                    height: "40px",
                                    backgroundColor: "#4779EF",
                                    padding: "7px 15px 5px 15px",
                                    borderRadius: "5px",
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    marginLeft: "33%",
                                    border: "none",
                                    outline: "none",
                                  }} onClick={() => setTimeout(() => window.location.reload(), 2000)}
                                >
                                  Copy Link
                                </button>
                              </CopyToClipboard>
                            </DialogContent>
                          </Dialog>
                          <MenuItem
                            onClick={() => {
                              if (Secondsessiondata?.session_id !== "") {
                                window.open(
                                  `https://gamemondev-service.indiumsoft.com/getReport?sessionID=${Secondsessiondata?.session_id}`
                                );
                              }
                            }}
                            style={{
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            }}
                          >
                            Download Report
                          </MenuItem>
                          <MenuItem
                            onClick={opener}
                            style={{
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            }}
                          >
                            Download log File
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                    }}
                    className="version-fonts3"
                  >
                    <span>Device:{""}</span>
                    <span
                      style={{
                        fontWeight: "600",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={Secondsessiondata?.device_name}
                    >
                      {/* {Secondsessiondata?.device_name} */}
                      {Secondsessiondata?.device_name
                        ? Secondsessiondata?.device_name.length > 10
                          ? Secondsessiondata?.device_name.substring(0, 8) +
                          "..."
                          : Secondsessiondata?.device_name
                        : ""}
                    </span>
                  </div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                      marginLeft: "-0.5px",
                    }}
                    className="version-fonts3"
                  >
                    <span>OS version:{""}</span>
                    <span style={{ fontWeight: "600" }}>
                      {Secondsessiondata?.os_version}
                    </span>
                  </div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                      marginLeft: "-0.5px",
                    }}
                    className="version-fonts3"
                  >
                    <span>GPU:{""}</span>
                    <span
                      style={{
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={
                        Secondsessiondata?.gpu_vendor?.includes(",")
                          ? Secondsessiondata?.gpu_vendor
                            .split(",", 2)
                            .join(",")
                          : Secondsessiondata?.gpu_vendor
                      }
                    >
                      {Secondsessiondata?.gpu_vendor?.includes(",")
                        ? Secondsessiondata?.gpu_vendor
                          ?.split(",")[1]
                          ?.substring(0, 8) + "..."
                        : Secondsessiondata?.gpu_vendor?.substring(0, 8)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="user-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fonts"
                  >
                    User
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                    }}
                    title={Secondsessiondata?.user_name}
                    className="user-fonts"
                  >
                    {Secondsessiondata?.user_name}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fonts"
                  >
                    Session Title
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                    }}
                    title={Secondsessiondata?.sessionname}
                    className="user-fonts"
                  >
                    {Secondsessiondata?.sessionname ? (
                      <>
                        {/* {sessionTitle !== null ? selectedDevice:sessionTitle  } */}
                        {Secondsessiondata?.sessionname.length > 20
                          ? Secondsessiondata?.sessionname.substring(0, 20) +
                          "..."
                          : Secondsessiondata?.sessionname}
                        <span style={{ float: "right" }}>
                          <EditIcon
                            variant="outlined"
                            color="primary"
                            titleAccess="Edit"
                            sx={{
                              fontSize: 18,
                              marginLeft: "15%",
                              marginRight: "1%",
                              cursor: "pointer",
                              color: "#4779EF",
                            }}
                            onClick={handleEditClick}
                          />
                          <Dialog
                            PaperProps={{
                              style: {
                                boxShadow: "none",
                                width: "420px",
                                backgroundColor:
                                  currentTheme === "dark"
                                    ? "#1F2327"
                                    : "#FFFFFF",
                              },
                            }}
                            open={editDialogOpen}
                            onClose={handleDialogClose}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <DialogTitle
                                style={{
                                  fontSize: "20px",
                                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                }}
                              >
                                Edit Session Title
                              </DialogTitle>
                              <CancelIcon
                                onClick={handleClose}
                                style={{
                                  marginTop: "4.3%",
                                  cursor: "pointer",
                                  marginRight: "2.5vh",
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                }}
                              />
                            </div>
                            <Divider
                              style={{
                                margin: "0vw 1.5vw",
                                width: "350px",
                                backgroundColor: "grey",
                              }}
                            />
                            <DialogContent>
                              <TextField
                                value={newTitle}
                                onChange={handleInputChange}
                                // fullWidth
                                autoComplete="off"
                                style={{ marginTop: "10px", width: "350px" }}
                                InputProps={{
                                  placeholder: "Session Name",
                                  inputProps: {
                                    style: {
                                      color:
                                        currentTheme === "dark"
                                          ? "#FFFFFF"
                                          : "#000000",
                                      textTransform: "capitalize",
                                    },
                                  },
                                }}
                              />
                              <Button
                                style={{
                                  color: "#FFFFFF",
                                  backgroundColor: "#4779EF",
                                  borderRadius: "10px",
                                  width: "200px",
                                  height: "40px",
                                  fontSize: "15px",
                                  justifyContent: "center",
                                  marginTop: "20px",
                                  marginLeft: "80px",
                                }}
                                onClick={handleSaveTitle}
                              >
                                Save
                              </Button>
                            </DialogContent>
                          </Dialog>
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fonts"
                  >
                    Session Duration
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                    }}
                    className="user-fonts"
                  >
                    {Secondsessiondata?.total_duration !== undefined && (
                      <span>
                        {typeof Secondsessiondata.total_duration === 'number' ? (
                          <>{formatTime(Secondsessiondata.total_duration)}</>
                        ) : (
                          <>
                            {Secondsessiondata.total_duration.includes(':')
                              ? Secondsessiondata.total_duration
                              : formatTime(Secondsessiondata.total_duration)}
                          </>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                minWidth: "235px",
                width: "235px",
                borderRadius: "5px",
                marginLeft: "1vw",
              }}
            >
              {/* User Details Dropdown */}
              {role === "admin" && (
                <div className="dropdown-containerss" ref={UsermenuRef}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      closeDropdown(UserActive);
                      setUserActive(!UserActive);
                    }}
                    className="dropdown-buttonss"
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "white",
                      color: currentTheme === "dark" ? "white" : "black",
                      textTransform: "capitalize",
                    }}
                    ref={dropdownRef}
                  >
                    <div>
                      {User ? User : "User"}
                      <span className="down-arrowss">&#9660;</span>
                    </div>
                  </div>

                  <div
                    className="dropdown-contentss"
                    style={{
                      display: UserActive ? "block" : "none",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "white",
                    }}
                  >
                    <div
                      className="scrollable-listss"
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {FirstUserdata &&
                        FirstUserdata.slice()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((data, i) => (
                            <div
                              onClick={(e) => {
                                setUser(e.target.textContent);
                                auth.userId(data.id);
                                setUserActive(!UserActive);
                                singleUserItem(e, data, i);
                                Users(e, data, i);
                              }}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                              }}
                              key={i}
                            >
                              {data.name}
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {role === "admin" ? (
              <div
                style={{
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  color: currentTheme === "dark" ? "white" : "black",
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollBehavior: "smooth",
                }}
                className="sessionNote-Container"
              >
                <h3 className="font-weight">Session Notes</h3>
                <span style={{ float: "right", marginTop: "-30px" }}>
                  <AddIcon
                    className="add-icon"
                    titleAccess="Add"
                    onClick={handleSessionNotes}
                  ></AddIcon>
                </span>
                {notes !== null && (
                  <div>
                    <div>
                      {notes &&
                        notes?.map((note, index) => (
                          <div key={index}>
                            <button
                              onClick={handleNotesEditClick}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                                textTransform: "capitalize",
                                height: "30px",
                                width: "14vw",
                                cursor: "pointer",
                                // padding: '10px',
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                position: "relative",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {note.session_notes_title}
                            </button>

                            {/* default dialog to trigger others */}
                            <Dialog
                              PaperProps={{
                                style: {
                                  boxShadow: "none",
                                  width: "420px",
                                  backgroundColor:
                                    currentTheme === "dark"
                                      ? "#1F2327"
                                      : "#FFFFFF",
                                },
                              }}
                              open={editNotesDialogOpen}
                              onClose={handleNotesDialogClose}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginTop: "10px",
                                }}
                              >
                                <DialogTitle
                                  style={{
                                    fontSize: "20px",
                                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                >
                                  Session Details
                                </DialogTitle>
                                <CancelIcon
                                  onClick={handleClose}
                                  style={{
                                    marginTop: "4.3%",
                                    cursor: "pointer",
                                    marginRight: "2.5vh",
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                />
                              </div>
                              <Divider
                                style={{
                                  margin: "0vw 1.5vw",
                                  width: "350px",
                                  backgroundColor: "grey",
                                }}
                              />
                              <DialogContent style={{ height: "200px" }}>
                                <div
                                  style={{
                                    marginTop: "-5px",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Title:{" "}
                                  <span style={{ fontWeight: "400" }}>
                                    {note.session_notes_title}
                                  </span>
                                </div>
                                <div style={{ width: "350px" }}></div>
                                <div
                                  style={{
                                    marginTop: "17px",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                >
                                  Description:{" "}
                                </div>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    height: "40px",
                                    width: "350px",
                                  }}
                                >
                                  <TextField
                                    value={note.session_notes}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    inputProps={{
                                      style: {
                                        height: "80px",
                                        color: "#818E94",
                                        textTransform: "capitalize",
                                      },
                                    }}
                                  />
                                </div>
                              </DialogContent>
                              <DialogActions
                                style={{
                                  // marginLeft: "1vw",
                                  display: "flex",
                                  justifyContent: "row",
                                  marginRight: "3vw",
                                  gap: "20px",
                                }}
                              >
                                <List>
                                  <div
                                    style={{
                                      color: "#FFFFFF",
                                      marginTop: "8%",
                                      background: "#4779EF",
                                      borderRadius: "8px",
                                      width: "160px",
                                      height: "40px",
                                      padding: "3px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <ListItem
                                      button
                                      onClick={() => {
                                        // handleEditSessionNotes(note.id);
                                        handleClose();
                                      }}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        // marginTop: "4%",
                                        fontFamily:
                                          "normal normal bold 16px/21px Product Sans",
                                        textTransform: "uppercase",
                                      }}
                                      color="secondary"
                                      variant="outlined"
                                    >
                                      <Edit
                                        style={{ fontSize: 20, color: "white" }}
                                      />
                                      <span>Edit</span>
                                    </ListItem>
                                  </div>
                                </List>
                                <List>
                                  <div
                                    style={{
                                      color: "white",
                                      marginTop: "8%",
                                      background: "red",
                                      borderRadius: "8px",
                                      width: "160px",
                                      height: "40px",
                                      padding: "3px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <ListItem
                                      button
                                      onClick={() => {
                                        checksnote(note.id);
                                        handleClose();
                                      }}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        // marginTop: "4%",
                                        fontFamily:
                                          "normal normal bold 16px/21px Product Sans",
                                        textTransform: "uppercase",
                                      }}
                                      color="secondary"
                                      variant="outlined"
                                    >
                                      <Delete
                                        style={{ fontSize: 20, color: "white" }}
                                      />
                                      <span> Delete</span>
                                    </ListItem>
                                  </div>
                                </List>
                              </DialogActions>
                            </Dialog>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div>
                  <Dialog
                    open={sessionNote}
                    onClose={handleSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 350 },
                      style: {
                        borderRadius: "5px",
                        backgroundColor:
                          currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <DialogTitle
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Add Notes
                      </DialogTitle>
                      <CancelIcon
                        onClick={handleSessionNotesClose}
                        style={{
                          cursor: "pointer",
                          marginLeft: "150px",
                          marginTop: "20px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      />
                    </div>
                    <Divider
                      style={{ margin: "1vh", backgroundColor: "grey" }}
                    />
                    <DialogContent style={{ height: "285px" }}>
                      <div style={{ marginTop: "-5px", color: "#818E94" }}>
                        Title{" "}
                      </div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          onChange={handleInputSessionTitle}
                          fullWidth
                          autoComplete="off"
                          inputProps={{
                            style: {
                              height: "2px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "17px", color: "#818E94" }}>
                        Description
                      </div>
                      <div
                        style={{
                          marginTop: "3px",
                          height: "40px",
                          width: "300px",
                        }}
                      >
                        <TextField
                          onChange={handleInputSessionDescription}
                          fullWidth
                          autoComplete="off"
                          multiline
                          rows={4}
                          inputProps={{
                            style: {
                              height: "50px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleFormAddSubmit}
                        disabled={isSubmitting}
                        style={{
                          width: "300px",
                          height: "48px",
                          borderRadius: "14px",
                          marginTop: "62px",
                          backgroundColor: "#278EF1",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                        sans-serif`,
                        }}
                      >
                        Save
                      </Button>
                    </DialogContent>
                  </Dialog>
                </div>

                {/* Edit Session Notes */}
                <div>
                  <Dialog
                    open={editSessionNote}
                    onClose={handleEditSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 350 },
                      style: {
                        borderRadius: "5px",
                        backgroundColor:
                          currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <DialogTitle
                        style={{
                          fontSize: "20px",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Edit Session Notes{sessionNotekey?.sessionNoteDes}
                      </DialogTitle>
                      <CancelIcon
                        onClick={handleEditSessionNotesClose}
                        style={{
                          marginTop: "4.3%",
                          cursor: "pointer",
                          marginRight: "2.5vh",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      />
                    </div>
                    <Divider
                      style={{
                        margin: "0vw 1.5vw",
                        width: "350px",
                        backgroundColor: "grey",
                      }}
                    />
                    <DialogContent
                      style={{ height: "230px", overflow: "hidden" }}
                    >
                      <div
                        style={{
                          marginTop: "-5px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Title<span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ width: "350px" }}>
                        <TextField
                          value={editTitle}
                          onChange={handleInputEditSessionTitle}
                          fullWidth
                          autoComplete="off"
                          inputProps={{
                            style: {
                              height: "2px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "17px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Description<span style={{ color: "red" }}>*</span>
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          height: "40px",
                          width: "350px",
                        }}
                      >
                        <TextField
                          value={editSession}
                          onChange={handleInputEditSessionDescription}
                          fullWidth
                          autoComplete="off"
                          multiline
                          rows={4}
                          inputProps={{
                            style: {
                              height: "80px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        marginRight: "1.5vw",
                        gap: "20px",
                      }}
                    >
                      <List>
                        <div
                          style={{
                            color: "#FFFFFF",
                            marginTop: "8%",
                            background: "#4779EF",
                            borderRadius: "8px",
                            width: "160px",
                            height: "40px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={editnote}
                            style={{
                              paddingLeft: "30%",
                              // marginTop: "4%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Update
                          </ListItem>
                        </div>
                      </List>
                      <List>
                        <div
                          style={{
                            color: "#4779EF",
                            marginTop: "8%",
                            border: "2px solid #4779EF",
                            boxShadow: "white",
                            borderRadius: "8px",
                            width: "160px",
                            height: "40px",
                            padding: "7px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={handleEditSessionNotesClose}
                            style={{
                              paddingLeft: "30%",
                              marginTop: "-4%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </ListItem>
                        </div>
                      </List>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            ) : (
              // Add Icon for another user
              <div
                style={{
                  width: "16.5vw",
                  height: "225px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "8px",
                  boxShadow: "0px 3px 6px #0000000A",
                  position: "relative",
                  right: "-19px",
                  padding: "10px",
                  color: currentTheme === "dark" ? "white" : "black",
                }}
              >
                <h3 style={{ fontWeight: "600" }}>Session Notes</h3>
                <span style={{ float: "right", marginTop: "-30px" }}>
                  <AddIcon
                    sx={{
                      fontSize: 35,
                      color: "#4779EF",
                      cursor: "pointer",
                      boxShadow: "#0000004D",
                    }}
                    onClick={handleSessionNotes}
                    titleAccess="Add"
                  ></AddIcon>
                </span>
                {notes !== null && (
                  <div>
                    <div>
                      {notes &&
                        notes?.map((note, index) => (
                          <div key={index}>
                            <button
                              onClick={handleNotesEditClick}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                                textTransform: "capitalize",
                                height: "30px",
                                width: "14vw",
                                cursor: "pointer",
                                // padding: '10px',
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                position: "relative",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {note.session_notes_title}
                            </button>

                            {/* default dialog to trigger others */}
                            <Dialog
                              PaperProps={{
                                style: {
                                  boxShadow: "none",
                                  width: "420px",
                                  backgroundColor:
                                    currentTheme === "dark"
                                      ? "#1F2327"
                                      : "#FFFFFF",
                                },
                              }}
                              open={editNotesDialogOpen}
                              onClose={handleNotesDialogClose}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginTop: "10px",
                                }}
                              >
                                <DialogTitle
                                  style={{
                                    fontSize: "20px",
                                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                >
                                  Session Details
                                </DialogTitle>
                                <CancelIcon
                                  onClick={handleClose}
                                  style={{
                                    marginTop: "4.3%",
                                    cursor: "pointer",
                                    marginRight: "2.5vh",
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                />
                              </div>
                              <Divider
                                style={{
                                  margin: "0vw 1.5vw",
                                  width: "350px",
                                  backgroundColor: "grey",
                                }}
                              />
                              <DialogContent style={{ height: "200px" }}>
                                <div
                                  style={{
                                    marginTop: "-5px",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Title:{" "}
                                  <span style={{ fontWeight: "400" }}>
                                    {note.session_notes_title}
                                  </span>
                                </div>
                                <div style={{ width: "350px" }}></div>
                                <div
                                  style={{
                                    marginTop: "17px",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                >
                                  Description:{" "}
                                </div>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    height: "40px",
                                    width: "350px",
                                  }}
                                >
                                  <TextField
                                    value={note.session_notes}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    inputProps={{
                                      style: {
                                        height: "80px",
                                        color: "#818E94",
                                        textTransform: "capitalize",
                                      },
                                    }}
                                  />
                                </div>
                              </DialogContent>
                              <DialogActions
                                style={{
                                  // marginLeft: "1vw",
                                  display: "flex",
                                  justifyContent: "row",
                                  marginRight: "3vw",
                                  gap: "20px",
                                }}
                              >
                                <List>
                                  <div
                                    style={{
                                      color: "#FFFFFF",
                                      marginTop: "8%",
                                      background: "#4779EF",
                                      borderRadius: "8px",
                                      width: "160px",
                                      height: "40px",
                                      padding: "3px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <ListItem
                                      button
                                      onClick={() => {
                                        // handleEditSessionNotes(note.id);
                                        handleClose();
                                      }}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        // marginTop: "4%",
                                        fontFamily:
                                          "normal normal bold 16px/21px Product Sans",
                                        textTransform: "uppercase",
                                      }}
                                      color="secondary"
                                      variant="outlined"
                                    >
                                      <Edit
                                        style={{ fontSize: 20, color: "white" }}
                                      />
                                      <span>Edit</span>
                                    </ListItem>
                                  </div>
                                </List>
                                <List>
                                  <div
                                    style={{
                                      color: "white",
                                      marginTop: "8%",
                                      background: "red",
                                      borderRadius: "8px",
                                      width: "160px",
                                      height: "40px",
                                      padding: "3px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <ListItem
                                      button
                                      onClick={() => {
                                        checksnote(note.id);
                                        handleClose();
                                      }}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        // marginTop: "4%",
                                        fontFamily:
                                          "normal normal bold 16px/21px Product Sans",
                                        textTransform: "uppercase",
                                      }}
                                      color="secondary"
                                      variant="outlined"
                                    >
                                      <Delete
                                        style={{ fontSize: 20, color: "white" }}
                                      />
                                      <span> Delete</span>
                                    </ListItem>
                                  </div>
                                </List>
                              </DialogActions>
                            </Dialog>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {/* Adding Notes dialog*/}
                <div>
                  <Dialog
                    open={sessionNote}
                    onClose={handleSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 350 },
                      style: {
                        borderRadius: "5px",
                        backgroundColor:
                          currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <DialogTitle
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Add Notes
                      </DialogTitle>
                      <CancelIcon
                        onClick={handleSessionNotesClose}
                        style={{
                          cursor: "pointer",
                          marginLeft: "150px",
                          marginTop: "20px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      />
                    </div>
                    <Divider
                      style={{ margin: "1vh", backgroundColor: "grey" }}
                    />
                    <DialogContent style={{ height: "285px" }}>
                      <div style={{ marginTop: "-5px", color: "#818E94" }}>
                        Title{" "}
                      </div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          onChange={handleInputSessionTitle}
                          fullWidth
                          autoComplete="off"
                          inputProps={{
                            style: {
                              height: "2px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "17px", color: "#818E94" }}>
                        Description
                      </div>
                      <div
                        style={{
                          marginTop: "3px",
                          height: "40px",
                          width: "300px",
                        }}
                      >
                        <TextField
                          onChange={handleInputSessionDescription}
                          fullWidth
                          autoComplete="off"
                          multiline
                          rows={4}
                          inputProps={{
                            style: {
                              height: "50px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleFormAddSubmit}
                        disabled={isSubmitting}
                        style={{
                          width: "300px",
                          height: "48px",
                          borderRadius: "14px",
                          marginTop: "62px",
                          backgroundColor: "#278EF1",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                        sans-serif`,
                        }}
                      >
                        Save
                      </Button>
                    </DialogContent>
                  </Dialog>
                </div>
                {/* Editting session notes */}
                <div>
                  <Dialog
                    open={editSessionNote}
                    onClose={handleEditSessionNotesClose}
                    PaperProps={{
                      sx: { width: "70%", maxHeight: 350 },
                      style: {
                        borderRadius: "5px",
                        backgroundColor:
                          currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <DialogTitle
                        style={{
                          fontSize: "20px",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Edit Session Notes{sessionNotekey?.sessionNoteDes}
                      </DialogTitle>
                      <CancelIcon
                        onClick={handleEditSessionNotesClose}
                        style={{
                          marginTop: "4.3%",
                          cursor: "pointer",
                          marginRight: "2.5vh",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      />
                    </div>
                    <Divider
                      style={{
                        margin: "0vw 1.5vw",
                        width: "350px",
                        backgroundColor: "grey",
                      }}
                    />
                    <DialogContent
                      style={{ height: "230px", overflow: "hidden" }}
                    >
                      <div
                        style={{
                          marginTop: "-5px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Title<span style={{ color: "red" }}>*</span>
                      </div>
                      <div style={{ width: "350px" }}>
                        <TextField
                          value={editTitle}
                          onChange={handleInputEditSessionTitle}
                          fullWidth
                          autoComplete="off"
                          inputProps={{
                            style: {
                              height: "2px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "17px",
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        }}
                      >
                        Description<span style={{ color: "red" }}>*</span>
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          height: "40px",
                          width: "350px",
                        }}
                      >
                        <TextField
                          value={editSession}
                          onChange={handleInputEditSessionDescription}
                          fullWidth
                          autoComplete="off"
                          multiline
                          rows={4}
                          inputProps={{
                            style: {
                              height: "80px",
                              color: "#818E94",
                              textTransform: "capitalize",
                            },
                          }}
                        />
                      </div>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        marginRight: "1.5vw",
                        gap: "20px",
                      }}
                    >
                      <List>
                        <div
                          style={{
                            color: "#FFFFFF",
                            marginTop: "8%",
                            background: "#4779EF",
                            borderRadius: "8px",
                            width: "160px",
                            height: "40px",
                            padding: "3px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={editnote}
                            style={{
                              paddingLeft: "30%",
                              // marginTop: "4%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Update
                          </ListItem>
                        </div>
                      </List>
                      <List>
                        <div
                          style={{
                            color: "#4779EF",
                            marginTop: "8%",
                            border: "2px solid #4779EF",
                            boxShadow: "white",
                            borderRadius: "8px",
                            width: "160px",
                            height: "40px",
                            padding: "7px",
                            fontSize: "15px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={handleEditSessionNotesClose}
                            style={{
                              paddingLeft: "30%",
                              marginTop: "-4%",
                              fontFamily:
                                "normal normal bold 16px/21px Product Sans",
                            }}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </ListItem>
                        </div>
                      </List>
                    </DialogActions>
                  </Dialog>
                </div>
                {/* ////////////////// */}
              </div>
            )}
          </div>
        </div>

        {/* Metric Grid */}
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginLeft: "20vw",
            }}
            className="metric-grid"
          >
            {/* First Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.cpu_average_usage !== undefined
                      ? Math.min(Secondsessiondata?.cpu_average_usage, 100)
                      : 0
                  }
                  text="CPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 1}
                  selected2={selectedVideoGraph=== 1}
                  onClick={() => handleClickGraph(1)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.gpu_average_usage !== undefined
                      ? Secondsessiondata?.gpu_average_usage
                      : 0
                  }
                  text="GPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 2}
                  selected2={selectedVideoGraph=== 2}
                  onClick={() => handleClickGraph(2)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.memory_average_usage !== undefined
                      ? Secondsessiondata?.memory_average_usage
                      : 0
                  }
                  text="Average Memory Usage"
                  unit="MB"
                  max={parseFloat(Secondsessiondata?.ram_size) * 1024}
                  maxRef={
                    Secondsessiondata?.ram_size == null ||
                      Secondsessiondata?.ram_size == 0
                      ? "NA"
                      : Secondsessiondata?.ram_size
                  }
                  selected={selectedGraph === 3}
                  selected2={selectedVideoGraph=== 3}
                  onClick={() => handleClickGraph(3)}
                />
              </div>
            </div>
            {/* Second Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.average_fps_value !== undefined
                      ? Secondsessiondata?.average_fps_value
                      : 0
                  }
                  text="FPS"
                  unit=""
                  max={100}
                  selected={selectedGraph === 4}
                  selected2={selectedVideoGraph=== 4}
                  onClick={() => handleClickGraph(4)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.download_data_usage_average !== undefined
                      ? Secondsessiondata.download_data_usage_average > 1024
                        ? (
                          parseFloat(
                            Secondsessiondata.download_data_usage_average
                          ) / 1024
                        ).toFixed(2)
                        : parseFloat(
                          Secondsessiondata.download_data_usage_average
                        ).toFixed(2)
                      : 0
                  }
                  text="Total Data Downloaded"
                  unit={
                    Secondsessiondata?.download_data_usage_average !==
                      undefined &&
                      Secondsessiondata.download_data_usage_average > 1024
                      ? "MiB"
                      : "KiB"
                  }
                  max={
                    Secondsessiondata?.download_data_usage_average !==
                      undefined &&
                      Secondsessiondata.download_data_usage_average > 1024
                      ? 100
                      : 1024
                  }
                  selected={selectedGraph === 5}
                  selected2={selectedVideoGraph=== 5}
                  onClick={() => handleClickGraph(5)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.upload_data_usage_average !== undefined
                      ? Secondsessiondata.upload_data_usage_average > 1024
                        ? (
                          parseFloat(
                            Secondsessiondata.upload_data_usage_average
                          ) / 1024
                        ).toFixed(2)
                        : parseFloat(
                          Secondsessiondata.upload_data_usage_average
                        ).toFixed(2)
                      : 0
                  }
                  text="Total Data Uploaded"
                  unit={
                    Secondsessiondata?.upload_data_usage_average !==
                      undefined &&
                      Secondsessiondata.upload_data_usage_average > 1024
                      ? "MiB"
                      : "KiB"
                  }
                  max={
                    Secondsessiondata?.upload_data_usage_average !==
                      undefined &&
                      Secondsessiondata.upload_data_usage_average > 1024
                      ? 100
                      : 1024
                  }
                  selected={selectedGraph === 6}
                  selected2={selectedVideoGraph=== 6}
                  onClick={() => handleClickGraph(6)}
                />
              </div>
            </div>
            {/* Third Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.peak_memory_value !== undefined
                      ? Secondsessiondata?.peak_memory_value
                      : 0
                  }
                  text="Average Peak Memory"
                  unit="MB"
                  max={parseFloat(Secondsessiondata?.ram_size) * 1024}
                  maxRef={
                    Secondsessiondata?.ram_size == null ||
                      Secondsessiondata?.ram_size == 0
                      ? "NA"
                      : Secondsessiondata?.ram_size
                  }
                  selected={selectedGraph === 7}
                  selected2={selectedVideoGraph=== 7}
                  onClick={() => handleClickGraph(7)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.fps_stability !== undefined &&
                      !isNaN(Secondsessiondata?.fps_stability)
                      ? Secondsessiondata?.fps_stability
                      : 0
                  }
                  text="FPS Stability"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 8}
                  selected2={selectedVideoGraph=== 8}
                  onClick={handleBatteryClick}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value="0"
                  text="Battery Usage"
                  unit="%"
                  max={100}
                  onClick={handleBatteryClick}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "21vw",
              display: "grid",
              // gridTemplateColumns: "repeat(3, 1fr)",
              // gridTemplateRows: "repeat(2, 1fr)",
              gap: "1%",
              width: "100%",
              height: "100%",
              marginTop: "1rem",
            }}
          >
          <div style={{ gridRow: "1 / span 1" }}>
  <div
    style={{
      width: "18vw",
      height: "300px",
      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
      borderRadius: "10px",
      boxShadow: "0px 3px 6px #0000000A",
      overflow: "hidden",
      boxSizing: "border-box",
    }}
  >
    <p
      style={{
        color: currentTheme === "dark" ? "white" : "black",
        fontSize: "14px",
        padding: "5px",
        fontWeight: "bold",
      }}
    >
      Screen Flow
      <span style={{ marginLeft: "5%" }}>
        <button
          style={{
            width: "100px",
            height: "20px",
            borderRadius: "5px",
            color: "white",
            background:
              "transparent linear-gradient(180deg, #659AF5 0%, #4374EE 100%) 0% 0% no-repeat padding-box",
            border: "none",
            whiteSpace: "nowrap",
            cursor: "pointer",
            fontWeight: "600",
          }}
          onClick={videoFunction}
        >
          {video ? "Show Video" : "Show Image"}
        </button>
      </span>
    </p>
    <hr />
    {video === true ? (
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
        title="Explore"
      >
        <div
          style={{
            flex: "1",
            overflowClipMargin: "content-box",
            overflow: "clip",
            textAlign: "center",
            paddingRight: "10px",
          }}
        >
          {isLoading && (
            <div
              style={{
                fontSize: "12px",
                color: currentTheme === "dark" ? "white" : "black",
              }}
              title="fetching the image from server"
            >
              Fetching...
            </div>
          )}
          {imageError}
          <img
            style={{
              marginBottom: "1%",
              borderRadius: "5px",
              maxWidth: "65%",
              height: "8%",
              boxSizing: "border-box",
              color: currentTheme === "dark" ? "white" : "black",
              fontSize: "12px",
              whiteSpace:"nowrap"
            }}
            src={timeStamp}
            onLoad={handleImageLoads}
            onError={handleImageError}
            alt={timeNull !== null ? "Image Not Found!" : "Oops! Pick an Image!"}
          />
        </div>
        {timeNull !== null && (
          <TravelExploreIcon
            style={{
              fontSize: "2rem",
              color: currentTheme === "dark" ? "white" : "black",
              cursor: "pointer",
            }}
            onClick={handleZoomIconClick}
          />
        )}
      </div>
    ) : (
      <div style={{marginTop:"1%"}}>
        <VideoSection timestamp={timestamp} onProgress={handleProgress} videoUrl={videoUrl}/>
      </div>
    )}
  </div>

  {/* Modal */}
  {isModalOpen && (
    <div
      style={{
        position: "fixed",
        top: 10,
        left: 100,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
        zIndex: 999,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          cursor: "zoom-in",
        }}
      >
        <TransformWrapper>
          <TransformComponent>
            <img
              style={{
                margin: "0.5%",
                height: isPortrait ? "250px" : "450px",
                width: "220px",
                color: currentTheme === "dark" ? "white" : "black",
                fontSize: "12px",
                borderRadius: "5px",
                transform: `rotate(${rotationAngle}deg)`,
                transition: "0.5s ease-in-out",
                textAlign: "center",
              }}
              src={selectedImage}
              alt=""
            />
          </TransformComponent>
        </TransformWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: -23,
            left: 130,
            padding: "10px",
          }}
        >
          <CloseIcon
            style={{
              display: "flex",
              justifyContent: "top",
              alignItems: "top",
              fontSize: "2rem",
              color: currentTheme === "dark" ? "white" : "black",
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </div>
        <br />
        <ScreenRotationIcon
          style={{
            fontSize: "2rem",
            color: currentTheme === "dark" ? "white" : "black",
            cursor: "pointer",
            marginTop: "5px",
          }}
          onClick={rotateImage}
        />
      </div>
    </div>
  )}
</div>

            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "34vw",
                  height: "300px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                    // textTransform: "capitalize",
                  }}
                >
                  {/* {Secondsessiondata?.app_name} */}
                  {Secondsessiondata?.app_name
                    ? Secondsessiondata?.app_name.length > 25
                      ? Secondsessiondata?.app_name.substring(0, 25) + "..."
                      : Secondsessiondata?.app_name
                    : ""}
                  {/* {Secondsessiondata?.app_name && (Secondsessiondata.app_name.includes('.') ? Secondsessiondata.app_name.split('.').pop().slice(-20) : Secondsessiondata.app_name)} */}
                </p>
                {/* <hr /> */}
                {video === true ?(
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "auto",
                    overflowY: "hidden",
                    cursor: "pointer",
                    marginTop: "3vh",
                  }}
                  className="ScrollBars"
                >
                  {renderGraphComponent()}
                </div>
                ):(
<div>
<GraphSection
          currentTime={currentTime}
          onPointClick={handlePointClick}
          selectedVideoGraph={selectedVideoGraph}
        />
</div>
                )}
              </div>
            </div>
          </div>
          {/* Render Graph Component */}
          {/* <div currentTheme={currentTheme} style={{ marginLeft: "20vw" }}>
            {renderGraphComponent()}
          </div> */}
        </div>
      </div>
      <ToastContainer
        toastStyle={{
          marginTop: "4rem",
          borderRadius: "10px",
          backgroundColor: "white",
          alignContent: "center",
          height: 50,
          margin: 10,
          width: 300,
          closeOnClick: true,
          textAlign: "center",
        }}
        closeButton={false}
        hideProgressBar={false}
        autoClose={2000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
      />
    </div>
  );
}
