import React, { useContext, useState, useEffect, useRef, useMemo, } from "react";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CorporateFareSharpIcon from "@mui/icons-material/CorporateFareSharp";
import { Typography } from "@mui/material";
import "./filter.css";
import "./SuperAdminDashboard.css";
import { StylesProvider } from "@material-ui/core/styles";
import { format, parseISO, isValid, subMonths } from "date-fns";  // Import subMonths from date-fns
import { ThemePreferenceContext } from "../../../../index";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Grid } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import excelimage from '../../../../asset/excel.png'

export default function Dashboard() {
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);
  const currentUserId = JSON.parse(sessionStorage.getItem("currentSessionData"));
  const uid = auth.id || currentUserId;
  const [UserActive, setUserActive] = useState(false);
  const [OrgActive, setOrgActive] = useState(false);
  const [InvoiceDateActive, setInvoiceDateActive] = useState(false);

  // Calculate current date and six months back
  const currentDate = new Date();
  const sixMonthsBackDate = subMonths(currentDate, 6);

  // Set default start and end dates
  const [startDate, setStartDate] = useState(sixMonthsBackDate.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(currentDate.toISOString().split('T')[0]);

  const [startDropdownActive, setStartDropdownActive] = useState(false);
  const [endDropdownActive, setEndDropdownActive] = useState(false);
  const [pageScroll, setPageScroll] = useState(1);
  const [invoiceBook, setInvoiceBook] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [user_name, setuser_name] = useState();
  const [org_id, setorg_id] = useState();
  const [org_name, setorg_name] = useState();
  const [invoiceDate, setinvoiceDate] = useState([0]);
  const [invoiceDateSetting, setinvoiceDateSetting] = useState();
  const [invoiceDropDate, setinvoiceDropDate] = useState();
  const [invoicedetails, setinvoicedetails] = useState();
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [OpenInvoiceInfo, setOpenInvoiceInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setUserActive(false);
      setOrgActive(false);
      setInvoiceDateActive(false);
      setStartDropdownActive(false);
      setEndDropdownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return "";
    }
    return format(date, "dd-MMM-yyyy");
  }

  function formatBillingDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return "";
    }
    return format(date, "dd MMM '’'yy");
  }

  const handleInvoiceInfoOpen = () => {
    setOpenInvoiceInfo(true);
  };

  const handleInvoiceInfoClose = () => {
    setOpenInvoiceInfo(false);
  };

  useEffect(() => {
    axios
      .get("/organization", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setOrganizations(res.data.data);
      });
  }, []);

  const filteredOrganizations = organizations.filter((org) =>
    org.org_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = () => {
    axios
      .get(`/invoicealldetails`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        params: {
          startDate: startDate,
          endDate: endDate,
        }
      })
      .then((res) => {
        setinvoiceDate(res.data.data);
        setInvoiceBook(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const invoice_org = () => {
    // Extract selected organization IDs
    const selectedOrgIds = selectedOrgs.map(org => org.org_id);

    // Construct the URL with selected organization IDs
    const orgIdsParam = selectedOrgIds.join(',');

    axios
      .get(`/invoiceorg_details/${orgIdsParam}/${startDate}/${endDate}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setinvoiceDate(res.data.data);
        setInvoiceBook(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const invoice_id_details = (params) => {
    const invoiceDateSetting = params;
    axios
      .get(`/invoicedetails/${invoiceDateSetting}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        setinvoicedetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const invoice_id_details_download = (params) => {
    const invoiceDateSetting = params;
    axios
      .get(`/invoicedetails/${invoiceDateSetting}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        setinvoicedetails(res.data.data);
        if (res.status === 200) {
          window.open(
            `https://gamemondev-service.indiumsoft.com/getInvoiceReport/${invoiceDateSetting}`
          );
        } else {
          toast.error("No Log File for this Session !", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          var timer = setInterval(function () {
            clearInterval(timer);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rowDataInvoice = invoiceBook?.map((data) => {
    const date = new Date(data?.invoice_date);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
    const year = date.getFullYear();
    const formatedInvoiceDate = `${day}-${month}-${year}`;

    const date1 = new Date(data?.billing_start);
    const day1 = date1.getDate();
    const month1 = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date1);
    const year1 = date1.getFullYear().toString().slice(-2);
    const formatedBillingStart = `${day1} ${month1} ’${year1}`;

    const date2 = new Date(data?.billing_end);
    const day2 = date2.getDate();
    const month2 = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date2);
    const year2 = date2.getFullYear().toString().slice(-2);
    const formatedBillingEnd = `${day2} ${month2} ’${year2}`;

    const unitType = data?.unit_type;

    const formattedUnitType = unitType === 'Hour' ? 'Hrs' : (unitType === 'Minute' ? 'Mins' : unitType);
    return {
      org_name: data?.org_name,
      invoice_id: data?.invoice_id,
      invoice_date: formatedInvoiceDate,
      billing_start: formatedBillingStart,
      billing_end: formatedBillingEnd,
      billing_dates: `${formatedBillingStart} to ${formatedBillingEnd}`,
      total_unit_consumed: `${data?.total_unit_consumed}(${formattedUnitType})`,
      total_amount: `${data?.currency} ${data?.total_amount} `,
      created_at: data?.created_at,
      currency: data?.currency,
      due_date: data?.due_date,
      free_usage_per_month: data?.free_usage_per_month,
      unit_type: data?.unit_type,
      unit_rate: data?.unit_rate,
      min_unit: data?.min_unit,
      unit_price_post_min_unit: data?.unit_price_post_min_unit,
      unit_beyond_min_unit: data?.unit_beyond_min_unit,
    };
  });
  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const columnDefInvoice = [
    {
      headerName: "Org Name",
      field: "org_name",
      flex: 1,
      minWidth: 200,
      unSortIcon: true,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Invoice ID",
      field: "invoice_id",
      flex: 1,
      maxWidth: 120,
      unSortIcon: true,
    },
    {
      headerName: "Billing Period",
      field: "billing_dates",
      flex: 1,
      minWidth: 220,
      unSortIcon: true,
    },
    {
      headerName: "Invoice Date",
      field: "invoice_date",
      flex: 1,
      minWidth: 150,
      unSortIcon: true,
    },
    {
      headerName: "Units Consumed",
      field: "total_unit_consumed",
      flex: 1,
      minWidth: 157,
      unSortIcon: true,
    },
    {
      headerName: "Billable Amount",
      field: "total_amount",
      flex: 1,
      minWidth: 157,
      unSortIcon: true,
    },
    // { headerName: "PhoneNumber", field: "phone_number" },
    {
      headerName: "Actions",
      field: "id",
      flex: 1,
      minWidth: 110,
      cellRendererFramework: (params) => (
        <div>
          <InfoOutlinedIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
            onClick={() => {
              handleInvoiceInfoOpen();
              invoice_id_details(params.data.invoice_id);
            }}
            titleAccess="Invoice Info"
          ></InfoOutlinedIcon>
          {/* {invoiceDate.map((invoice) => ( */}
          <img
            src={excelimage}
            alt="Excel"
            style={{ margin: "10px", cursor: "pointer", width: "23px", height: "2%" }}
            onClick={() => {
              // setInvoiceDateActive(!InvoiceDateActive);
              // setinvoiceDateSetting(invoice.invoice_id);
              invoice_id_details_download(params.data.invoice_id);
            }}

            title="Download Invoice(Excel)"
          ></img>
          {/* ))} */}
        </div>
      ),
    },
  ];

  const defaultColDefsInvoice = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const toggleOrganization = (org) => {
    setSelectedOrgs((prevSelectedOrgs) =>
      prevSelectedOrgs.includes(org)
        ? prevSelectedOrgs.filter((o) => o !== org)
        : [...prevSelectedOrgs, org]
    );
  };

  return (
    <>
      <div
        style={{
          width: "82vw",
          position: "fixed",
          height: "85vh",
          marginTop: "2.5%",
        }}
      >
        <main>
          <div style={{ margin: 10 }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridTemplateRows: "repeat(2, 1fr)",
                gap: "1%",
                width: "100%",
                height: "100%",
                marginTop: "1rem",
              }}
            >
              <div
                style={{ gridRow: "1 / span 1" }}
              >
                <p
                  className="headertitle"
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    // marginTop: "-8.5vh",
                    paddingBottom: "8px",
                    fontSize: "14px"
                  }}
                >
                  Invoice Details
                </p>
                <div
                  style={{
                    height: "80vh",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "10px",
                    boxShadow: "0px 3px 6px #0000000A",
                    width: "80vw",
                  }}
                  className="performance-trends"
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >

                    {/* Org Dropdown */}
                    <div className="dropdown-containers">
  <div
    style={{
      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
      fontSize: "14px",
      fontWeight: "600"
    }}
  >
    Organization Name
  </div>
  <div
    onClick={(e) => {
      e.stopPropagation();
      setOrgActive(!OrgActive);
    }}
    className="dropdown-button"
    style={{
      backgroundColor:
        currentTheme === "dark" ? "#292E33" : "white",
      color: currentTheme === "dark" ? "white" : "black",
      textTransform: "capitalize",
      width: "200px",
      height: "30px",
      marginTop: "1%"
    }}
    ref={dropdownRef}
  >
    <div style={{ marginTop: "-0.6vh", whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis", }}>
      {selectedOrgs.length > 0
        ? selectedOrgs.map((org) => org.org_name).join(", ")
        : "Choose Organizations"}
      <span className="down-arrow">&#9660;</span>
    </div>
  </div>
  {OrgActive && (
    <div
      style={{
        display: "block",
        backgroundColor:
          currentTheme === "dark" ? "#292E33" : "white",
        color: currentTheme === "dark" ? "white" : "black",
        width: "200px"
      }}
      className="dropdown-content"
    >
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: "170px",
          margin: "10px",
          padding: "5px",
          borderRadius: "5px",
          textTransform: "capitalize",
          border:
            currentTheme === "dark"
              ? "1px solid #646667"
              : "1px solid #DFDFDF",
          outline: "none",
          backgroundColor:
            currentTheme === "dark" ? "#292E33" : "white",
          color: currentTheme === "dark" ? "white" : "black"
        }}
      />
      <div
        style={{
          backgroundColor:
            currentTheme === "dark" ? "#292E33" : "white",
          color: currentTheme === "dark" ? "white" : "black",
          marginLeft: "1%",
          padding: "10px",
          maxHeight: "200px",
          overflowY: "auto",
          marginTop: "-15px"
        }}
      >
        {filteredOrganizations
          .filter((org) => org.status_org !== "N")
          .sort((a, b) =>
            a.org_name.localeCompare(b.org_name)
          )
          .map((org) => (
            <div
              key={org.org_id}
              onClick={(e) => {
                e.stopPropagation();
                toggleOrganization(org);
                setOrgActive(false); // Close dropdown on select
              }}
              style={{
                backgroundColor:
                  currentTheme === "dark"
                    ? "#292E33"
                    : "white",
                color:
                  currentTheme === "dark"
                    ? "white"
                    : "black",
                textTransform: "capitalize",
                padding: "5px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
            >
              <input
                type="checkbox"
                checked={selectedOrgs.includes(org)}
                onChange={() => toggleOrganization(org)}
                onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                style={{ marginRight: "8px" }}
              />
              {org.org_name}
            </div>
          ))}
      </div>
    </div>
  )}
</div>

                    {/* Start Date Dropdown */}
                    <div className="dropdown-containers" style={{ marginLeft: "2%", }}>
                      <div style={{
                        color:
                          currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`, fontSize: "14px", fontWeight: "600", marginLeft: "100px"
                      }}>Invoice Date</div>
                      {/* <br/> */}
                      <div style={{ display: "flex", flexDirection: "row", marginTop: "0.5%", }}>
                        <div style={{}}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOutsideClick(startDropdownActive);
                              setStartDropdownActive(!startDropdownActive);
                              setEndDropdownActive(false);
                            }}
                            className="dropdown-buttons"
                            style={{
                              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "white" : "black",
                              width: "140px",
                              height: "30px",
                            }}
                            ref={dropdownRef}
                          >
                            <p style={{ marginTop: "-0.6vh" }}>
                              {startDate ? formatDate(startDate) : "From Date"}
                            </p>
                          </div>
                          <div
                            className="dropdown-contents"
                            style={{
                              display: startDropdownActive ? "block" : "none",
                              width: "140px",
                            }}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <StylesProvider injectFirst>
                                <br />
                                <div className="dates" style={{ marginLeft: "8%" }}>
                                  <TextField
                                    id="date"
                                    label="From"
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setStartDate(value);

                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      max: endDate,
                                      style: {
                                        padding: 1.5,
                                        width: "120px",
                                      },
                                    }}
                                  />
                                  <br />
                                  <br />
                                </div>
                              </StylesProvider>
                            </div>
                          </div>
                        </div>

                        <div style={{
                          marginLeft: "2%"
                        }}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOutsideClick(endDropdownActive);
                              setEndDropdownActive(!endDropdownActive);
                              setStartDropdownActive(false);

                            }}
                            className="dropdown-buttons"
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "white",
                              color: currentTheme === "dark" ? "white" : "black",
                              width: "140px",
                              height: "30px",
                            }}
                            ref={dropdownRef}
                          >

                            <p style={{ marginTop: "-0.6vh" }}>
                              {endDate ? formatDate(endDate) : "To Date"}
                            </p>
                          </div>
                          <div
                            className="dropdown-contents"
                            style={{
                              display: endDropdownActive ? "block" : "none",
                              width: "140px",
                            }}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setDate(e.target.textContent);
                                // setEndDropdownActive(!endDropdownActive);
                              }}
                            >
                              <StylesProvider injectFirst>
                                <br></br>
                                <div className="dates" style={{ marginLeft: "8%" }}>

                                  <TextField
                                    id="date"
                                    label="To"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setEndDate(value);
                                      // setEndDropdownActive(false);

                                    }}


                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      min: startDate,
                                      style: {
                                        padding: 1.5,
                                        width: "120px",
                                      },
                                    }}
                                  />
                                  <br></br>
                                  <br></br>
                                </div>
                              </StylesProvider>
                            </div>
                          </div>
                        </div>
                        {/* Search Botton */}
                        <div style={{
                          marginLeft: "2%"
                        }}>
                          <div
                            style={{
                              height: "30px",
                              width: "120px",
                              backgroundColor: "#4779EF",
                              color: "#FFFFFF",
                              borderRadius: "5px",
                              float: "right",
                              cursor: "pointer",
                              border: "none",
                              // marginTop: '-12px',
                              // marginLeft: "10px",
                              // marginTop: "30px",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                              outline: "none",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                            onClick={invoice_org}
                          >
                            {/* <SearchIcon style={{margin:"5%",}}/> */}
                            <div style={{ display: "flex", justifyContent: "center", }}>Search</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Date Dropdown */}
                    {/* <div className="dropdown-containers" style={{ marginLeft: "2%" }}>
                      <div style={{
                        color:
                          currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`, fontSize: "14px", fontWeight: "600",marginTop:"13%"
                      }}>{""}</div>
                      
                    </div> */}
                  </div>
                  <hr
                    style={{
                      marginTop: "1.5vh",
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                    }}
                  />
                  <div
                    style={{
                      // overflowY: "scroll",
                      // height: "40vh",
                      fontSize: "14px",
                    }}
                  >
                    <div style={{ marginLeft: "1%", marginTop: "-2%" }}>

                      <div
                        className="ag-theme-alpine"
                        style={{
                          height: "80vh",
                          // marginTop: "1%",
                          // backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                          // scrollbarColor:" #278ef1",
                          // scrollbarWidth:"thin",
                        }}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            margin: "0 12px",
                            paddingTop: "20px",
                          }}
                        >

                        </Grid>
                        <br></br>
                        <div
                          style={{
                            border:
                              currentTheme === "dark"
                                ? "1px solid #646667"
                                : "1px solid #DFDFDF",
                          }}
                          className={
                            currentTheme === "dark"
                              ? "ag-theme-alpine-dark"
                              : "ag-theme-alpine"
                          }
                          id="table-grid"
                        >
                          {/* <div style={gridStyle}> */}
                          <AgGridReact
                            columnDefs={columnDefInvoice}
                            defaultColDef={defaultColDefsInvoice}
                            rowData={rowDataInvoice}
                            pagination={true}
                            paginationPageSize={10}
                          // paginationNumberFormatter={paginationNumberFormatter1}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Dialog
                PaperProps={{
                  style: {
                    boxShadow: "none",
                    // maxWidth: "xl",
                    minWidth: "1000px",
                    // width: "40vw",
                    maxheight: "720px",
                    backgroundColor:
                      currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                    overflow: "hidden"
                  },
                }}
                open={OpenInvoiceInfo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <DialogTitle
                    style={{
                      fontSize: "18px",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                  // id="alert-dialog-title"
                  >
                    Invoice Info
                  </DialogTitle>
                  <CancelIcon
                    onClick={handleInvoiceInfoClose}
                    style={{
                      marginTop: "2%",
                      cursor: "pointer",
                      marginRight: "2.5vh",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                  />
                </div>
                <Divider
                  style={{
                    margin: "0vw 1.5vw",
                    width: "95%",
                    backgroundColor: "grey",
                  }}
                />
                {/* {loading ? ( // Conditional rendering based on loading state
              <div className="loader-container">
                <div className="loader"></div>
              </div> // Render loader while loading
            ) : ( */}
                <div style={{ marginTop: "1%", marginLeft: "3%" }}>
                  {invoicedetails && invoicedetails.length > 0 ? (
                    invoicedetails.map((invoice, index) => (
                      <div key={index} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black" }}>
                          <div style={{ width: "200px", fontWeight: "600", marginLeft: "-50px", color: "#A0A0A0" }}>Organization Name</div>
                          <span style={{ width: "200px", fontWeight: "600", textTransform: "capitalize" }}>{invoice.org_name}</span>
                          <span style={{ width: "200px", fontWeight: "600", color: "#A0A0A0" }}>Invoice ID</span>
                          <span style={{ width: "200px", fontWeight: "600" }}>{invoice.invoice_id}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black" }}>
                          <div style={{ width: "200px", fontWeight: "600", marginLeft: "-50px", color: "#A0A0A0" }}>Invoice Date</div>
                          <span style={{ width: "200px", fontWeight: "600", textTransform: "capitalize" }}>{formatDate(invoice.invoice_date)}</span>
                          <span style={{ width: "200px", fontWeight: "600", color: "#A0A0A0" }}>Billing Period</span>
                          <span style={{ width: "200px", fontWeight: "600", whiteSpace: "nowrap" }}>{formatBillingDate(invoice.billing_start)} to {formatBillingDate(invoice.billing_end)}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black" }}>
                          <div style={{ width: "200px", fontWeight: "600", marginLeft: "-50px", color: "#A0A0A0" }}>Total Units Consumed</div>
                          <span style={{ width: "200px", fontWeight: "600", textTransform: "capitalize" }}>{invoice.total_unit_consumed}({invoice.unit_type === "Hour" ? "Hrs" : invoice.unit_type === "Minute" ? "Mins" : invoice.unit_type})</span>
                          <span style={{ width: "200px", fontWeight: "600", color: "#A0A0A0" }}>Billing To</span>
                          <span style={{ width: "200px", fontWeight: "600" }}>{invoice.bc_first_name}{" "}{invoice.bc_last_name},<br />{invoice.billing_city},{invoice.billing_country}-{invoice.billing_zip_code}.</span>
                        </div>

                        {invoice.category_id === 2 ? (
                          <>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "1px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black" }}>
                              <div style={{ width: "200px", fontWeight: "600", marginLeft: "-30px" }}>Description</div>
                              <span style={{ width: "200px", fontWeight: "600" }}>Consumed Units ({invoice.unit_type === "Hour" ? "Hrs" : invoice.unit_type === "Minute" ? "Mins" : invoice.unit_type})</span>
                              <span style={{ width: "200px", fontWeight: "600" }}>Unit Price ({invoice.currency})</span>
                              <span style={{ width: "200px", fontWeight: "600", textAlign: "right" }}>Amount ({invoice.currency})</span>
                            </div>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "5px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            {/* <br/> */}
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>Tier 1</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", whiteSpace: "nowrap"
                              }}>{invoice.min_unit} - {invoice.free_usage_per_month}(Free Usage*) = {invoice.min_unit - invoice.free_usage_per_month}</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{invoice.unit_rate}</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.amount_min_unit).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>Tier 2</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{invoice.total_unit_consumed - invoice.min_unit}</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{(invoice.unit_price_post_min_unit)}</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.amount_beyond_min_unit).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>

                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{ width: "200px", fontWeight: "600" }}>Total Billable Amount ({invoice.currency})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.total_amount).toFixed(2)}</span>


                            </div>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "1px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>Tax</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>CGST({invoice.tax_parameter.taxes[0].CGST})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.tax_parameter.taxes[0].CGST_amount).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>SGST({invoice.tax_parameter.taxes[1].SGST})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.tax_parameter.taxes[1].SGST_amount).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{ width: "200px", fontWeight: "600", }}>Total GST</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.tax_parameter.taxes.find(tax => tax["Total GST"] !== undefined)["Total GST"]).toFixed(2)}</span>

                            </div>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "5px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginTop: "-10px", paddingBottom: "10px", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>Tax ID: <span style={{
                                color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{invoice.tax_id}</span></div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{ width: "200px", fontWeight: "600", }}>Net Bill Amount ({invoice.currency})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.net_amount).toFixed(2)}</span>

                            </div>
                          </>) : (
                          <>  <hr style={{
                            marginRight: "2%",
                            marginTop: "5px",
                            marginBottom: "5px",
                            border: "none",
                            borderBottom:
                              currentTheme === "dark"
                                ? "0.5px solid #A2B1C2"
                                : "1px solid #e6e6e6",
                          }} />
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px" }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>Description</div>
                              <span style={{ width: "200px", fontWeight: "600" }}>Consumed Units ({invoice.unit_type === "Hour" ? "Hrs" : invoice.unit_type === "Minute" ? "Mins" : invoice.unit_type})</span>
                              <span style={{ width: "200px", fontWeight: "600" }}>Unit Price ({invoice.currency})</span>
                              <span style={{ width: "200px", fontWeight: "600", textAlign: "right" }}>Amount ({invoice.currency})</span>
                            </div>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "1px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            {/* <br/> */}
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>Flat Pricing</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{invoice.min_unit - invoice.free_usage_per_month}</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{invoice.unit_rate}</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.amount_min_unit).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{ width: "200px", fontWeight: "600" }}>Total Billable Amount ({invoice.currency})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.total_amount).toFixed(2)}</span>


                            </div>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "5px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>Tax</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>CGST({invoice.tax_parameter.taxes[0].CGST})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.tax_parameter.taxes[0].CGST_amount).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>SGST({invoice.tax_parameter.taxes[1].SGST})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.tax_parameter.taxes[1].SGST_amount).toFixed(2)}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{ width: "200px", fontWeight: "600", }}>Total GST</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}>{(invoice.tax_parameter.taxes.find(tax => tax["Total GST"] !== undefined)["Total GST"]).toFixed(2)}</span>

                            </div>
                            <hr style={{
                              marginRight: "2%",
                              marginTop: "5px",
                              marginBottom: "5px",
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-evenly", color: currentTheme === "dark" ? "white" : "black", marginTop: "-10px", paddingBottom: "10px", marginLeft: "-30px", }}>
                              <div style={{ width: "200px", fontWeight: "600" }}>Tax ID: <span style={{
                                color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949",
                              }}>{invoice.tax_id}</span></div>
                              <div style={{ width: "200px", fontWeight: "600" }}>{""}</div>
                              <span style={{ width: "200px", fontWeight: "600" }}>Net Bill Amount ({invoice.currency})</span>
                              <span style={{
                                width: "200px", fontWeight: "600", color:
                                  currentTheme === "dark" ? "#A2B1C2" : "#494949", textAlign: "right"
                              }}> {(invoice.net_amount).toFixed(2)}</span>

                            </div>

                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center", color: currentTheme === "dark" ? "white" : "black", paddingBottom: "20px" }}>No invoice details available</div>
                  )}

                </div>
                {/* )} */}
              </Dialog>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
